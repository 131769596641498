import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import CardHeader from '../../components/CardHeader'
import { toast } from 'react-toastify'

import '../../assets/styles/slider-edit.css'
import { getBaseAPIRootUrl, toastConfigColoured } from '../../utils/helper'
import {
  fetchUtils,
  uploadHomeSliderImage,
  deleteHomeSliderImage,
  editImportantLinks,
} from '../../apis/utils'
import DeleteIcon from '../../assets/icons/DeleteIcon'
import EditIcon from '../../assets/icons/EditIcon'

const AdminImportantLinks = () => {
  const user = JSON.parse(localStorage.getItem('profile')) || {}

  const [impLinks, setImpLinks] = useState([])
  const [addEditLinkModal, setAddEditLinkModal] = useState(false)
  const [deleteLinkModal, setDeleteLinkModal] = useState(false)

  const [linkLabel, setLinkLabel] = useState('')
  const [linkUrl, setLinkUrl] = useState('')
  const [selectedId, setSelectedId] = useState('')

  useEffect(() => {
    initFetch()
  }, [])

  const initFetch = async () => {
    const _rawUtils = await fetchUtils()
    const _links = _rawUtils?.footer?.importantLinks || []
    setImpLinks(_links)
  }

  const handleLinkAddEdit = async () => {
    if (addEditLinkModal && !linkLabel) {
      toast.info('Please enter the link Label!', toastConfigColoured)
      return
    }
    if (addEditLinkModal && !linkUrl) {
      toast.info('Please enter the link URL!', toastConfigColoured)
      return
    }

    let _importantLinks = []
    if (selectedId && deleteLinkModal) {
      _importantLinks = impLinks.filter((_itm) => _itm._id !== selectedId)
    } else if (selectedId) {
      _importantLinks = impLinks.map((_itm) => {
        if (_itm._id === selectedId) {
          return {
            label: linkLabel,
            url: linkUrl,
            _id: _itm._id
          }
        } 
        return _itm
      })
    } else {
      _importantLinks = [
        ...impLinks,
        {
          label: linkLabel,
          url: linkUrl
        }
      ]
    }
    
    const addRes = await editImportantLinks(_importantLinks)

    console.log('image upload res: ', addRes)
    if (addRes.success) {
      toast.success(addRes.message || 'Successfully Done', toastConfigColoured)
    } else {
      toast.error(addRes.message || 'Something Wrong. Please try again!', toastConfigColoured)
    }

    setDeleteLinkModal(false)
    setAddEditLinkModal(false)
    setSelectedId('')
    initFetch()
  }
  
  
  return (
    <div className="admin-container custom-container imp-links common-hover  mh-container">
      <Modal
        open={deleteLinkModal}
        title={`Delete Link confirmation`}
        className="slider-update-modal"
        onCancel={() => {
          setSelectedId('')
          setDeleteLinkModal(false)
        }}
        footer={[
          <button
            key="back"
            className="custom-button custom-button-sm"
            onClick={() => {
              setSelectedId('')
              setDeleteLinkModal(false)
            }}
            style={{ marginRight: '10px' }}
          >
            Cancel
          </button>,
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            onClick={handleLinkAddEdit}
          >
            Confirm
          </button>,
        ]}
      >
        <div className="form-container one-row slider">
          <p>
            Are you sure to delete this Link?
          </p>
        </div>
      </Modal>
      <Modal
        open={addEditLinkModal}
        title={`${selectedId ? 'Edit' : 'Add'} Link`}
        className="slider-update-modal"
        onCancel={() => {
          setAddEditLinkModal(false)
          setSelectedId('')
        }}
        footer={[
          <button
            key="back"
            className="custom-button custom-button-sm"
            onClick={() => {
              setAddEditLinkModal(false)
              setSelectedId('')
            }}
            style={{ marginRight: '10px' }}
          >
            Cancel
          </button>,
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            onClick={handleLinkAddEdit}
          >
            {selectedId ? 'Edit' : 'Add'}
          </button>,
        ]}
      >
        <div className="form-container one-row slider">
          <div className="form-item">
            <label>Link Label</label>
            <input
              type='text'
              value={linkLabel}
              onChange={(e) => setLinkLabel(e.target.value)}
              placeholder='e.g. Home'
            />
          </div>

          <div className="form-item">
            <label>Link URL</label>
            <input
              type='text'
              value={linkUrl}
              onChange={(e) => setLinkUrl(e.target.value)}
              placeholder='e.g. http://bholabarassociation.com/'
            />
          </div>
        </div>
      </Modal>

      <CardHeader title="Add/Edit Important Links" classNm="bg-slider" />
      <div className="add-new-link">
        <button
          className="btn-add-slider bg-[#006A4E] hover:bg-[#C99D45] text-white font-normal py-2 px-4  "
          onClick={() => setAddEditLinkModal(true)}
        >
          Add New Link
        </button>
      </div>
      <div className="link-edit-container-body">
        <table className="w-[100%] table-design">
          <thead>
            <tr className="text-[14px] text-[#444] leading-[20px] w-full">
              <th>#</th>
              <th>Label</th>
              <th>URL</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>

          <tbody className="w-[100%]">
            {impLinks.map((_item, _in) => (
              <tr
                className="text-[14px] text-[#444] leading-[18px] w-full "
                key={_in}
              >
                <td>{_in + 1}</td>
                <td>{_item.label}</td>
                <td>{_item.url}</td>
                <td
                  className="edit-icon"
                  onClick={() => {
                    setSelectedId(_item._id)
                    setLinkLabel(_item.label)
                    setLinkUrl(_item.url)
                    setAddEditLinkModal(true)
                  }}
                >
                  <EditIcon />
                </td>
                <td
                  className="delete-icon"
                  onClick={() => {
                    setSelectedId(_item._id)
                    setDeleteLinkModal(true)
                  }}
                >
                  <DeleteIcon />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AdminImportantLinks
