import axios from "../config/axios";
import { getToken } from "../utils/helper";

const get = async (url) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + getToken(),
      },
    };
    const { data } = await axios.get(url, config);
    // console.log("get: ", url, data);
    return data
  } catch (_error) {
    console.log("get error: ", url, _error);
    return {
      error: true,
      message: _error?.response?.data?.message || 'Something went wrong!'
    };
  }
}


const _delete = async (url) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + getToken(),
      },
    };
    const { data } = await axios.delete(url, config);
    console.log("delete: ", url, data);
    return data
  } catch (_error) {
    console.log("delete error: ", url, _error);
    return {
      error: true,
      message: _error?.response?.data?.message || 'Something went wrong!'
    };
  }
}


const post = async (url, body) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + getToken(),
      },
    };
    const { data } = await axios.post(url, body, config);
    // console.log("get: ", url, data);
    return data
  } catch (_error) {
    console.log("post error: ", url, _error);
    return {
      error: true,
      message: _error?.response?.data?.message || 'Something went wrong!'
    };
  }
}


const put = async (url, body) => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + getToken(),
      },
    };
    const { data } = await axios.put(url, body, config);
    // console.log("get: ", url, data);
    return data
  } catch (_error) {
    console.log("post error: ", url, _error);
    return {
      error: true,
      message: _error?.response?.data?.message || 'Something went wrong!'
    };
  }
}
const Api = {
  get,
  post,
  put,
  delete: _delete
}
export default Api