import React, { useEffect, useState } from 'react';
import { fetchNewses } from '../apis/news';
import * as moment from 'moment';
import { DDMMYYYY } from '../utils/helper';

const Marquee = () => {
  const [newsTitles, setNewsTitles] = useState('')

  useEffect(() => {
    initFetch()
  }, [])

  const initFetch = async () => {
    let _titles = ''
    const _newses = await fetchNewses()
    const _sortedNews = _newses
      .map((_it) => ({
        ..._it,
        sortingDate: moment(_it.readableDate, DDMMYYYY).format('YYYY-MM-DD'),
      }))
      .sort((_a, _b) =>
        _a.sortingDate > _b.sortingDate
          ? -1
          : _a.sortingDate < _b.sortingDate
          ? 1
            : 0
      )
      .slice(0, 3)
      .map((_it, _in) => {
        if (_in > 0) {
          _titles += ' **** '
        }
        _titles += _it.title
        return _it
      })
    setNewsTitles(_titles)
  }

  return (
    <div className="overflow-hidden whitespace-nowrap">
      <div
        className="inline-block"
        style={{
          animation: 'marquee 25s linear infinite', // Slowed down the speed
          whiteSpace: 'nowrap',
        }}
      >
        <span className="text-red-700 text-xl">{newsTitles}</span>
      </div>

      <style jsx>{`
        @keyframes marquee {
          0% { transform: translateX(100%); }
          100% { transform: translateX(-100%); }
        }
      `}</style>
    </div>
  )
}

export default Marquee;
