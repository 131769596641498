import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import CardHeader from '../../components/CardHeader'
import { toast } from 'react-toastify'

import '../../assets/styles/slider-edit.css'
import { getBaseAPIRootUrl, toastConfigColoured } from '../../utils/helper'
import {
  fetchUtils,
  updateAboutUs,
  uploadAboutUsHeaderImage,
} from '../../apis/utils'


const AboutUsEdit = () => {
  const user = JSON.parse(localStorage.getItem('profile')) || {}

  const [aboutUsInfo, setAboutUsInfo] = useState({})
  const [description, setDescription] = useState('')
  const [imageFile, setImageFile] = useState('')
  

  useEffect(() => {
    initFetch()
  }, [])

  const initFetch = async () => {
    const _rawUtils = await fetchUtils()
    const _aboutUs = _rawUtils?.aboutUs || {}
    setAboutUsInfo(_aboutUs)
    setDescription(_aboutUs?.description || '')
  }

  const handleContactUsUpdate = async () => {
    if (!imageFile) {
      toast.info('Please select an image!', toastConfigColoured)
      return
    }
    if (!description) {
      toast.info('Please enter Description!', toastConfigColoured)
      return
    }
    
    
    
    const addRes = await uploadAboutUsHeaderImage(imageFile)
    let postData = {
      description
    }
    const addres2 = await updateAboutUs(postData)


    console.log('contact us update res: ', addres2)
    if (addRes.success) {
      toast.success(addRes.message || 'Successfully Done', toastConfigColoured)
    } else {
      toast.error(addRes.message || 'Something Wrong. Please try again!', toastConfigColoured)
    }
    
    initFetch()
  }
  
  
  return (
    <div className="admin-container custom-container common-hover mh-container">
      
      <CardHeader title="Update Contact Info" classNm="bg-slider" />
      <div className="form-container one-row slider">
        <div className="form-item">
          <label>Title Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImageFile(e.target.files?.[0] || null)}
          />
        </div>
        <div className="form-item">
          <label>Description</label>
          <textarea
            rows={5}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder='Enter Description'
          />
        </div>
        <div style={{textAlign: 'center', margin: '2rem 0'}}>
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            onClick={handleContactUsUpdate}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  )
}

export default AboutUsEdit
