import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import EditIcon from "../../assets/icons/EditIcon";
import { toast } from "react-toastify";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import { DatePicker, Modal } from "antd";
import { getToken, toastConfigColoured } from "../../utils/helper";
import Select from "react-select";
import Api from "../../apis/api";
import axios from "../../config/axios";
import * as dayjs from "dayjs";
import CardHeader from "../../components/CardHeader";
import Spinner from "../../components/Spinner";
let searchTimeOut = null

const commonInputClassNames = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"

const AdminLibrary = () => {
  const navigate = useNavigate();

  const _user = JSON.parse(localStorage.getItem("profile")) || {}

  const [isLoading, setIsLoading] = useState(true)
  const [contentType, setContentType] = useState('book') // category
  const [booksTotal, setBooksTotal] = useState([]);
  const [booksFiltered, setBooksFiltered] = useState([]);
  const [bookDeleteModal, setBookDeleteModal] = useState(false);
  const [selectedBookId, setSelectedBookId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSearchCats, setSelectedSearchCats] = useState([])

  // category
  const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false)
  const [selectedCatId, setSelectedCatId] = useState('')
  const [selectedCatDeleteId, setSelectedCatDeleteId] = useState('')
  const [categories, setCategories] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [categoryName, setCategoryName] = useState('')



  //Book add
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [selectedBook, setSelectedItem] = useState({})
  const [bookTitle, setBookTitle] = useState('')
  const [bookId, setBookId] = useState('')
  const [authorName, setAuthorName] = useState('')
  const [selectedCategory, setSelectedCategory] = useState({ label: 'Select Category', value: '' })
  const [quantity, setQuantity] = useState(0)
  const [edition, setEdition] = useState('')
  const [publishedDate, setPublishedDate] = useState(null)
  const [stockDate, setStockDate] = useState(null)

  const [coverPhoto, setCoverPhoto] = useState();
  //   {
  //   file: null,
  //   uploaded: false,
  //   uploading: false,
  //   percentage: 0,
  // }

  useEffect(() => {
    initFetch();
  }, []);

  const initFetch = async () => {
    setIsLoading(true)
    await fetchBooks()
    const countRes = await Api.get('/books/total')
    console.log('count res: ', countRes)
    if (countRes.success) {
      setBooksTotal(countRes?.data?.total || 0)
    } else {
      setBooksTotal(0)
    }
    const _cats = await Api.get('/category');
    setCategories(_cats.data || [])
    setIsLoading(false)
  };

  const fetchBooks = async (_q = '') => {
    const _books = await Api.get(`/books?${_q}`)
    console.log('books: ', _books)
    setBooksFiltered(_books?.data || []);
  }

  useEffect(() => {
    clearTimeout(searchTimeOut)
    let _q = `search=${searchQuery}`
    if (selectedSearchCats.length) {
      const _ids = selectedSearchCats.map((_it) => _it.value).join(',')
      _q += `&categoryIds=${_ids}`
    }
    searchTimeOut = setTimeout(async () => {
      setIsLoading(true)
      await fetchBooks(_q)
      setIsLoading(false)
    }, 500);
  }, [searchQuery, selectedSearchCats]);

  useEffect(() => {
    setCategoryOptions(categories?.map((_it) => ({ label: _it.categoryName, value: _it._id })) || [])
  }, [categories])

  //// Paginate Start
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffSet, setItemOffset] = useState(0);
  const [paginationData, setPaginationData] = useState([]);
  const itemsPerPage = 20;

  useEffect(() => {
    const temps =
      booksFiltered.map((_it, _in) => ({ ..._it, _idx: _in })) || [];
    setPaginationData(temps);
  }, [booksFiltered]);

  useEffect(() => {
    const endOffset = itemOffSet + itemsPerPage;
    setCurrentItems(paginationData.slice(itemOffSet, endOffset));
    setPageCount(Math.ceil(paginationData.length / itemsPerPage));
  }, [itemOffSet, itemsPerPage, paginationData]);

  const handlePageClick = (e) => {
    const newOffset = (e.selected * itemsPerPage) % paginationData.length;
    setItemOffset(newOffset);
  };
  //// Paginate End

  const handleBookDelete = async () => {
    console.log('selected book id: ', selectedBookId)
    if (!selectedBookId) {
      return
    }
    let _data = await Api.delete('/books/' + selectedBookId)
    console.log('book delete data: ', _data)
    setSelectedBookId('')
    if (_data?.success) {
      toast.info(_data.message, toastConfigColoured);
      setBookDeleteModal(false);
      setSelectedBookId("");
      initFetch();
    } else {
      toast.warn(
        "Something went wrong! Please try again!",
        toastConfigColoured
      );
    }
  };

  const handleBookAddEdit = async () => {
    if (!bookTitle) {
      toast.info("Book Title cannot be empty!", toastConfigColoured);
      return;
    }

    if (!selectedCategory?.value) {
      toast.info("Category must be selected!", toastConfigColoured);
      return;
    }
    if (!bookId) {
      toast.info("Book ID cannot be empty!", toastConfigColoured);
      return;
    }


    const bookData = {
      quantity: quantity,
      title: bookTitle,
      categoryId: selectedCategory?.value,
      bookId: bookId,
      author: authorName,
      edition: edition,
      stockDate: stockDate,
      publishedDate: publishedDate,
      adminId: _user?._id,
    };

    console.log('bookData: ', bookData)

    const formData = new FormData();

    Object.keys(bookData).forEach((key) => {
      if (bookData[key] !== undefined && bookData[key] !== null) {
        formData.append(key, bookData[key]);
      }
    });


    if (coverPhoto) {
      formData.append('bookImage', coverPhoto);
    }


    const config = {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + getToken(),
      },
    }

    let _res;

    if (selectedBookId) {
      _res = await axios.put('/books/' + selectedBookId, formData, config);
    } else {
      _res = await axios.post('/books', formData, config);
    }

    console.log("add book _res", _res);
    if (_res && _res.status === 200) {
      toast.info(selectedBookId ? "Successfully Updated" : "Successfully Created new Book", toastConfigColoured);


      setAddModalOpen(false);
      resetModalData();
      initFetch();
    } else {
      toast.warn(
        _res.message || "Something went wrong! Please try again.",
        toastConfigColoured
      );
    }
  };

  const handleCategoryAddEdit = async () => {
    const _body = {
      categoryName
    }
    let _data;
    if (selectedCatId) {
      console.log('cat update: ', selectedCatId)
      _data = await Api.put('/category/' + selectedCatId, _body)
    } else {
      _data = await Api.post('/category', _body)
    }
    console.log('create category res.data: ', _data)
    setAddCategoryModalOpen(false)
    setCategoryName('')
    initFetch()
  }

  const handleCategoryDelete = async () => {
    let _data = await Api.delete('/category/' + selectedCatDeleteId)
    setSelectedCatDeleteId('')
    initFetch()
  }

  const resetModalData = () => {
    setBookTitle('')
    setAuthorName('')
    setSelectedCategory({ label: 'Select Category', value: '' })
    setQuantity(0)
    setEdition('')
    setBookId('')
    setPublishedDate(dayjs(new Date()))
    setStockDate(dayjs(new Date()))
  };

  return (
    <div className="admin-container custom-container library">

      {/* delete category  */}
      <Modal
        open={selectedCatDeleteId}
        title={`Delete Category confirmation`}
        className="user-update-modal"
        onCancel={() => {
          setSelectedCatDeleteId('');
        }}
        footer={[
          <button
            key="back"
            className="custom-button custom-button-sm"
            onClick={() => {
              setSelectedCatDeleteId('')
            }}
            style={{ marginRight: "10px" }}
          >
            Cancel
          </button>,
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            onClick={handleCategoryDelete}
          >
            Confirm
          </button>,
        ]}
      >
        <div className="form-container one-row user">
          <p>Are you sure to delete this category?</p>
        </div>
      </Modal>

      {/* add/edit category  */}
      <Modal
        bodyStyle={{
          backgroundColor: "white",
        }}
        open={addCategoryModalOpen}
        title="Add Category"
        onCancel={() => {
          setAddCategoryModalOpen(false);
        }}

        footer={[
          <button
            key="back"
            className="custom-button custom-button-sm"
            onClick={() => {
              setAddCategoryModalOpen(false);
            }}
            style={{ marginRight: "10px" }}
          >
            Cancel
          </button>,
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            onClick={handleCategoryAddEdit}
          >
            Submit
          </button>,
        ]}
      >
        <div className="update-form-container modalInput">

          <label
            htmlFor="first_name"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Category Name
          </label>
          <input
            type="text"
            id="first_name"
            className={commonInputClassNames}
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />

        </div>
      </Modal>

      {/* delete book  */}
      <Modal
        open={bookDeleteModal}
        title={`Delete Book confirmation`}
        className="user-update-modal"
        onCancel={() => {
          setBookDeleteModal(false);
        }}
        footer={[
          <button
            key="back"
            className="custom-button custom-button-sm"
            onClick={() => {
              setBookDeleteModal(false);
            }}
            style={{ marginRight: "10px" }}
          >
            Cancel
          </button>,
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            onClick={handleBookDelete}
          >
            Confirm
          </button>,
        ]}
      >
        <div className="form-container one-row user">
          <p>Are you sure to delete this book?</p>
        </div>
      </Modal>

      {/* add/edit book  */}
      <Modal
        bodyStyle={{
          backgroundColor: "white",
        }}
        open={addModalOpen}
        title="Add Book"
        className="profile-update-modal"
        onCancel={() => {
          setAddModalOpen(false);
        }}
        footer={[
          <button
            key="back"
            className="custom-button custom-button-sm"
            onClick={() => {
              setAddModalOpen(false);
            }}
            style={{ marginRight: "10px" }}
          >
            Cancel
          </button>,
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            onClick={handleBookAddEdit}
          >
            Submit
          </button>,
        ]}
      >
        <div className="update-form-container modalInput">
          <div className="grid gap-6 mb-6 md:grid-cols-2">

            <div>
              <label
                htmlFor="first_name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Book Title
              </label>
              <input
                type="text"
                id="first_name"
                className={commonInputClassNames}
                value={bookTitle}
                onChange={(e) => setBookTitle(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="last_name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Author Name
              </label>
              <input
                type="text"
                id="last_name"
                className={commonInputClassNames}
                value={authorName}
                onChange={(e) => setAuthorName(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="bookid"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Book ID
              </label>
              <input
                type="text"
                id="bookid"
                className={commonInputClassNames}
                value={bookId}
                onChange={(e) => setBookId(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="Category"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Category
              </label>
              <Select
                options={categoryOptions}
                isSearchable={true}
                onChange={(newV) => setSelectedCategory(newV)}
                value={selectedCategory}
              // className={commonInputClassNames}
              />
            </div>

            <div>
              <label
                htmlFor="bookid"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Book Edition
              </label>
              <input
                type="text"
                id="bookid"
                className={commonInputClassNames}
                value={edition}
                onChange={(e) => setEdition(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="website"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Cover Image
              </label>
              <input
                type="file"
                id="website"
                className={commonInputClassNames}
                placeholder={coverPhoto}
                onChange={(e) => {
                  setCoverPhoto(e.target.files?.[0])
                }}
              />
            </div>
            <div>
              <label
                htmlFor="visitors"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Quantity
              </label>
              <input
                type="number"
                id="visitors"
                className={commonInputClassNames}
                placeholder={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                required
              />
            </div>

            <div>
              <label
                htmlFor="website"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Stock Date
              </label>
              <DatePicker
                value={stockDate}
                onChange={(val) => setStockDate(val)}
                className={commonInputClassNames + ' antd-datepicker-custom'}
              />
            </div>
            <div>
              <label
                htmlFor="website"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Published Date
              </label>
              <DatePicker
                value={publishedDate}
                onChange={(val) => setPublishedDate(val)}
                className={commonInputClassNames + ' antd-datepicker-custom'}
              />
            </div>
          </div>
        </div>
      </Modal>
      <div className="common-hover custom-card custom-column ">
        <CardHeader title={contentType === 'book' ? 'Books' : 'Categories'} />
        <div className="title-container flex justify-between">
          <div className="title">
            <h3><span className="mr-5">Searched: {booksFiltered.length}</span><span>Total: {booksTotal}</span></h3>
          </div>
          <div className="bood-add-btn form-item">
            {_user.isAdmin && (
              <button
                className="bg-[#006A4E] hover:bg-[#C99D45] text-white font-normal py-2 px-4  "
                onClick={() => setContentType((_prev) => _prev === 'book' ? 'category' : 'book')}
              >
                Manage {contentType === 'book' ? 'Categories' : 'Books'}
              </button>
            )}

          </div>
        </div>
        {contentType === 'book' && (
          <>
            <div className="search-container form-container library-search items-end gap-5">
              <div className="form-item">
                <label htmlFor="search">Search</label>
                <input
                  type="text"
                  placeholder="Search by Title/Author"
                  id="search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div className="form-item">
                <label htmlFor="search">Category</label>
                <Select
                  options={categoryOptions}
                  isSearchable={true}
                  isMulti
                  onChange={(_selected) => setSelectedSearchCats(_selected)}
                />
              </div>

              <div className="bood-add-btn form-item">
                {_user.isAdmin && (
                  <button
                    className="bg-[#006A4E] hover:bg-[#C99D45] text-white font-normal py-2 px-4  "
                    onClick={() => setAddModalOpen(true)}
                  >
                    Add Book
                  </button>
                )}

              </div>
            </div>
            <div className="table-container">
              {isLoading ? (
                <Spinner width="200" color="#4fa94d" />
              ) : (
                <table className="w-[100%] table-design">
                  <thead>
                    <tr className="text-[14px] text-[#444] leading-[20px] w-full">
                      <th>#</th>
                      <th>Id</th>
                      <th>Title</th>
                      <th>Author</th>
                      <th>Category</th>
                      <th>Stock</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody className="w-[100%]">
                    {currentItems.map((_item, _in) => (
                      <tr
                        className="text-[14px] text-[#444] leading-[18px] w-full "
                        key={_in}
                      >
                        <td>{_item._idx + 1}</td>
                        <td>{_item.bookId}</td>
                        <td>{_item.title}</td>
                        <td>{_item.author}</td>
                        <td>{_item?.category?.categoryName || '-'}</td>
                        <td>{_item.quantity}</td>
                        <td className={_user.isAdmin ? "w-60" : ""}>
                          <div className={`grid ${_user.isAdmin ? 'grid-cols-3' : ''} justify-center items-center content-center`}>
                            {_user.isAdmin && (
                              <>
                                <span
                                  className="edit-icon"
                                  onClick={() => {
                                    console.log('selected category: ', categoryOptions.find((_it) => _it.value === _item.categoryId))
                                    setSelectedBookId(_item._id)
                                    setSelectedItem(_item)
                                    setBookTitle(_item.title)
                                    setAuthorName(_item.author)
                                    setQuantity(_item.quantity)
                                    setSelectedCategory(categoryOptions.find((_it) => _it.value === _item.categoryId))
                                    setEdition(_item.edition)
                                    setBookId(_item.bookId)
                                    setPublishedDate(dayjs(_item.publishedDate))
                                    setStockDate(dayjs(_item.publishedDate))
                                    setAddModalOpen(true)
                                  }}
                                >
                                  <EditIcon />
                                </span>
                                <span
                                  className="delete-icon"
                                  onClick={() => {
                                    setSelectedBookId(_item._id);
                                    setBookDeleteModal(true);
                                  }}
                                >
                                  <DeleteIcon />
                                </span>
                              </>
                            )}
                            <button
                              key="back"
                              className="custom-button custom-button-xs"
                              onClick={() => {
                                navigate('/book-details?id=' + _item._id)
                              }}
                            >
                              View
                            </button>
                          </div>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>

            {currentItems.length > 0 && (
              <div className="pagination-container">
                <ReactPaginate
                  breakLabel={"..."}
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  pageCount={pageCount}
                  previousLabel="Previous"
                  marginPagesDisplayed={3}
                  //renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-num"
                  previousLinkClassName="page-num"
                  nextLinkClassName="page-num"
                  activeLinkClassName="active"
                />
              </div>
            )}
          </>
        )}
        {contentType === 'category' && (
          <>
            <div className="bood-add-btn form-item text-end my-4">
              <button
                className="bg-[#006A4E] hover:bg-[#C99D45] text-white font-normal py-2 px-4  "
                onClick={() => setAddCategoryModalOpen(true)}
              >
                Add Category
              </button>
            </div>
            <div className="table-container">
              <table className="w-[100%] table-design">
                <thead>
                  <tr className="text-[14px] text-[#444] leading-[20px] w-full">
                    <th>#</th>
                    <th>Name</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>

                <tbody className="w-[100%]">
                  {categories.map((_item, _in) => (
                    <tr
                      className="text-[14px] text-[#444] leading-[18px] w-full text-center"
                      key={_in}
                    >
                      <td>{_in + 1}</td>
                      <td>{_item.categoryName}</td>
                      <td
                        className="edit-icon"
                        onClick={() => {
                          setCategoryName(_item.categoryName)
                          setSelectedCatId(_item._id);
                          setAddCategoryModalOpen(true)
                        }}
                      >
                        <EditIcon />
                      </td>
                      <td
                        className="delete-icon"
                        onClick={() => {
                          setSelectedCatDeleteId(_item._id);
                        }}
                      >
                        <DeleteIcon />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

      </div>
    </div>
  );
};

export default AdminLibrary
