import React, { useEffect, useState } from "react";
import { fetchUtils } from "../apis/utils";
import slider1 from "../assets/slider/slider1.jpg";
import slider2 from "../assets/slider/slider2.jpg";
import slider3 from "../assets/slider/slider3.jpg";
import slider4 from "../assets/slider/slider4.jpg";
import slider5 from "../assets/slider/slider5.jpg";
import { getBaseAPIRootUrl } from "../utils/helper";
import Spinner from "./Spinner";
// images must be an array of urls , if using Next JS this could something like
// const images = ['/img/img1.png', '/img/img2.png', '/img/img3.png']
// images must be an array of urls , if using Next JS this could something like
// const images = ['/img/img1.png', '/img/img2.png', '/img/img3.png']
const Carousel = () => {
  const [imagesLoading, setImagesLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    initFetch();
  }, []);

  const initFetch = async () => {
    const _utils = await fetchUtils();
    const _images = _utils?.home?.sliderImages || [];
    setImages(_images);
    setImagesLoading(false);
  };

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <>
      {imagesLoading ? (
        <Spinner />
      ) : (
        <div className="relative w-full h-[24rem] md:h-[32rem] overflow-hidden">
          <div
            className="flex w-full h-full transition-transform ease-in-out duration-500"
            style={{
              transform: `translateX(-${currentIndex * 100}%)`,
            }}
          >
            {images.length === 0 ? (
              <img
                src={slider1}
                alt="Default"
                className="w-full h-full object-cover"
              />
            ) : (
              images.map((_image, _idx) => (
                <div
                  key={_idx}
                  className="w-full h-full flex-shrink-0"
                  style={{
                    backgroundImage: `url(${getBaseAPIRootUrl() +
                      `home-slider/${_image.index + _image.extension}`})`,
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                  }}
                >
                </div>
              ))
            )}
          </div>

          <button
            onClick={prevSlide}
            className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-white p-1 md:p-2 rounded-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-8 h-8 md:w-12 md:h-12" // Adjust the icon size here
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            onClick={nextSlide}
            className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-white p-1 md:p-2 rounded-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-8 h-8 md:w-12 md:h-12" // Adjust the icon size here
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      )}
    </>
  );
};

export default Carousel;
