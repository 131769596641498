import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import CardHeader from '../../components/CardHeader'
import { toast } from 'react-toastify'

import '../../assets/styles/slider-edit.css'
import { getBaseAPIRootUrl, toastConfigColoured } from '../../utils/helper'
import {
  fetchUtils,
  editImportantLinks,
  updateContactUs,
} from '../../apis/utils'
import DeleteIcon from '../../assets/icons/DeleteIcon'
import EditIcon from '../../assets/icons/EditIcon'

const ContactUsEdit = () => {
  const user = JSON.parse(localStorage.getItem('profile')) || {}

  const [contactInfo, setContactInfo] = useState({})
  const [address, setAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [webLink, setWebLink] = useState('')

  useEffect(() => {
    initFetch()
  }, [])

  const initFetch = async () => {
    const _rawUtils = await fetchUtils()
    const _links = _rawUtils?.contactInfo || {}
    setContactInfo(_links)
    setAddress(_links?.address || '')
    setPhone(_links?.phone || '')
    setEmail(_links?.email || '')
    setWebLink(_links?.webLink || '')
  }

  const handleContactUsUpdate = async () => {
    if (!address) {
      toast.info('Please enter address!', toastConfigColoured)
      return
    }
    if (!phone) {
      toast.info('Please enter phone!', toastConfigColoured)
      return
    }
    if (!email) {
      toast.info('Please enter email!', toastConfigColoured)
      return
    }
    
    if (!webLink) {
      toast.info('Please enter Web Link!', toastConfigColoured)
      return
    }
    
    const postData = {
      address,
      phone,
      email,
      webLink
    }
    
    const addRes = await updateContactUs(postData)

    console.log('image upload res: ', addRes)
    if (addRes.success) {
      toast.success(addRes.message || 'Successfully Done', toastConfigColoured)
    } else {
      toast.error(addRes.message || 'Something Wrong. Please try again!', toastConfigColoured)
    }
    
    initFetch()
  }
  
  
  return (
    <div className="admin-container custom-container common-hover mh-container">
      
      <CardHeader title="Update Contact Info" classNm="bg-slider" />
      <div className="form-container one-row slider">
        <div className="form-item">
          <label>Address</label>
          <input
            type='text'
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder='Enter Address'
          />
        </div>
        <div className="form-item">
          <label>Phone</label>
          <input
            type='text'
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder='Enter Phone Number'
          />
        </div>
        <div className="form-item">
          <label>Email</label>
          <input
            type='text'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder='Enter Email'
          />
        </div>
        <div className="form-item">
          <label>Web Link</label>
          <input
            type='text'
            value={webLink}
            onChange={(e) => setWebLink(e.target.value)}
            placeholder='Enter Web Link'
          />
        </div>
        <div style={{textAlign: 'center', margin: '2rem 0'}}>
          <button
            key="submit"
            type="primary"
            className="custom-button custom-button-sm"
            onClick={handleContactUsUpdate}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  )
}

export default ContactUsEdit
